import React, { useEffect, useState } from 'react';
import { TabbedShowLayout, Tab } from 'react-admin';
import DatePicker from 'react-datepicker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Aftercare,
  AftercareLocation,
  CalendarDate,
  Client,
  Diagnosis,
  Doctor,
  Pet,
  Runnable,
  Veterinarian,
  Event,
  Consumer,
  MemorialOption,
  AftercareStatus,
} from './types';
import { get } from '../../utils/Api';
import { ModalDialog } from './ModalDialog';
import { SingleDatePicker } from 'react-dates';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { ClientSelectBundle } from './ClientSelectBundle';
import { PetSelectBundle } from './PetSelectBundle';
import {
  createEvent,
  deleteEvent,
  updateEvent,
  verifyDate,
} from '../../utils/AppointmentUtils';
import { getAppointmentTypeById } from '../../utils/AppointmentTypes';
import {
  getMemorialOption,
  getPetMemorialOption,
  getTotalAftercareCharge,
} from '../../utils/MemorialOptionUtils';
import { AlertDialog } from './AlertDialog';
import Select from '@material-ui/core/Select';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { referralMap } from '../../utils/Referrals';
import { removeNonNumeric } from '../../utils/StringUtils';

interface Props {
  callback: Consumer<{ success: boolean; message: string }>;
  closeCallback: Runnable;
  selectedDate: CalendarDate | null;
  type: 'read' | 'create' | 'edit';
  event: Event | undefined;
}
const timeStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      '& .react-datepicker__input-container input': {
        width: '100%',
        padding: '13px 5px',
        height: '0.563em',
        border: 0,
        background: '#0000000D',
        flexDirection: 'column',
        boxSizing: 'border-box',
      },
    },
    timeContainer: {
      maxWidth: '47%',
      flexDirection: 'column',
    },
    tabContent: {
      marginBottom: '40px',
      display: 'block',
      '& .DateInput_1': {
        background: 'transparent',
      },
      '& .DateInput_input': {
        background: 'transparent',
        border: 'none',
        fontSize: '0.8rem',
        lineHeight: '14px',
        height: '0.563em',
      },
      '& .DateInput_input__focused': {
        border: 'none',
        background: 'transparent',
        fontSize: '0.8rem',
        lineHeight: '14px',
        height: '0.563em',
      },
      '& .SingleDatePickerInput': {
        background: 'rgba(0, 0, 0, 0.05)',
      },
      '& .SingleDatePickerInput__withBorder': {
        border: 'none',
      },
      '& .SingleDatePickerInput_calendarIcon': {
        padding: '5px 4px',
        marginLeft: 0,
      },
    },
    firstItem: {
      marginTop: '48px',
      marginBottom: '10.19px',
    },
    label: {
      marginBottom: '10.19px',
      marginTop: '22px',
    },
    twoDivided: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    afters: {
      justifyContent: 'flex-start',
      alignItems: 'start',
    },
    twoDividedColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '47%',
      justifyContent: 'space-between',
    },
    dividedField: {
      width: '47%',
    },
    tinyDividedField: {
      maxWidth: '29px',
      width: '100%',
    },
    addOnLabel: {
      '&.MuiTypography-root': {
        fontSize: '0.625rem',
      },
    },
    petNotes: {
      backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
    },
    textLeft: {
      width: '60%',
      marginBottom: '10.19px',
      marginTop: '22px',
    },
    textRight: {
      width: '40%',
      textAlign: 'right',
      marginBottom: '10.19px',
      marginTop: '22px',
    },
    numberInput: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& .MuiInputBase-root input:not(#searchEntries)': {
        [theme.breakpoints.down('sm')]: {
          padding: '10px 8px',
        },
      },
    },
    referralBorder: {
      borderBottom: '1px solid #000',
      borderRadius: '0px',
    },
  };
});
export const AppointmentFormModal: React.FC<Props> = ({
  callback,
  closeCallback,
  selectedDate,
  type,
  event,
}) => {
  const [date, setDate] = useState<Moment | null>(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [client, setClient] = useState<Client | null>(null);
  const [selectedVeterinarians, setSelectedVeterinarians] = useState<
    Veterinarian[]
  >([]);
  const [veterinarians, setVeterinarians] = useState<Veterinarian[]>([]);
  const [veterinarianSearchText, setVeterinarianSearchText] = useState<string>(
    '',
  );
  const [doctor, setDoctor] = useState<Doctor | null>(null);
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [doctorSearchText, setDoctorSearchText] = useState<string>('');
  const [diagnosis, setDiagnosis] = useState<Diagnosis | null>(null);
  const [diagnosisArr, setDiagnosisArr] = useState<Diagnosis[]>([]);
  const [diagnosisSearchText, setDiagnosisSearchText] = useState<string>('');
  const [aftercare, setAftercare] = useState<Aftercare | null>(null);
  const [aftercares, setAftercares] = useState<Aftercare[]>([]);
  const [memorialOptions, setMemorialOptions] = useState<MemorialOption[]>([]);
  const [aftercareSearchText, setAftercareSearchText] = useState<string>('');
  const [
    aftercareLocation,
    setAftercareLocation,
  ] = useState<AftercareLocation | null>(null);
  const [
    aftercareStatus,
    setAftercareStatus,
  ] = useState<AftercareStatus | null>(null);
  const [aftercareLocations, setAftercareLocations] = useState<
    AftercareLocation[]
  >([]);
  const [
    aftercareLocationSearchText,
    setAftercareLocationSearchText,
  ] = useState<string>('');
  const [pet, setPet] = useState<Pet | null>(null);
  const [appointmentType, setAppointmentType] = useState(
    selectedDate?.allDay ? 'Duty' : 'Hold',
  );
  const [oGAppointmentType, setOGAppointmentType] = useState(
    selectedDate?.allDay ? 'Duty' : 'Hold',
  );
  const [topic, setTopic] = useState<string>('');
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [goToDateFocus, setGoToDateFocus] = useState(false);
  const [consultationCharge, setConsultationCharge] = useState<number>(0.0);
  const [euthanasiaCharge, setEuthanasiaCharge] = useState<number>(0.0);
  const [aftercareCharge, setAftercareCharge] = useState<number>(0.0);
  const [doctorPayment, setDoctorPayment] = useState<number>(0.0);
  const [crematoryPayment, setCrematoryPayment] = useState<number>(0.0);
  const [clayPaws, setClayPaws] = useState<number>(0);
  const [inkPaws, setInkPaws] = useState<number>(0);
  const [nosePrints, setNosePrints] = useState<number>(0);
  const [referral, setReferral] = useState<string>('');
  const [petNote, setPetNote] = useState<string>('');
  const [contactPreference, setContactPreference] = useState<string>('');
  const [finalEvent, setFinalEvent] = useState<Event | null>(null);
  const [notValid, setNotValid] = useState(true);
  const [transportOnly, setTransportOnly] = useState(false);
  const [lateCancel, setLateCancel] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const classes = timeStyles();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const onEditorStateChange = (
    editorState: React.SetStateAction<EditorState>,
  ) => {
    setEditorState(editorState);
  };

  const ccMlk = aftercareLocations?.find(location => {
    return location.name === 'Compassionate Care MLK';
  });

  if (type === 'create' && ccMlk && null === aftercareLocation) {
    setAftercareLocation(ccMlk);
  }

  useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent());
    setPetNote(html);
  }, [editorState]);

  const saveAppointment = async () => {
    if (finalEvent !== null) {
      // Disable button after click to prevent duplicated actions
      setNotValid(true);
      const verify = await verifyDate(finalEvent);
      if (verify && type === 'create') {
        setAlertOpen(true);
      } else {
        completeSave();
      }
    }
  };
  const completeSave = () => {
    if (finalEvent) {
      console.log(removeNonNumeric(finalEvent.startTime));
      console.log(removeNonNumeric(finalEvent.endTime));

      // Users can't select end date, so we need to adjust endTime by 1 day when an event ends next day
      if (
        removeNonNumeric(finalEvent.startTime) >
        removeNonNumeric(finalEvent.endTime)
      ) {
        // Convert dates to UTC for proper DB storage
        finalEvent.endTime = new Date(
          moment(finalEvent.endTime).add(1, 'day').toDate(),
        ).toUTCString();
      } else {
        // Convert dates to UTC for proper DB storage
        finalEvent.endTime = new Date(finalEvent.endTime).toUTCString();
      }

      // Convert dates to UTC for proper DB storage
      finalEvent.startTime = new Date(finalEvent.startTime).toUTCString();

      if (event && oGAppointmentType === appointmentType) {
        updateEvent(finalEvent).then(_res => {
          if (_res['@type'] === 'hydra:Error') {
            callback({
              success: false,
              message: 'There was an error updating the event.',
            });
          } else {
            callback({
              success: true,
              message: 'Event updated successfully.',
            });
          }
        });
      } else {
        createEvent(finalEvent).then(_res => {
          if (_res['@type'] === 'hydra:Error') {
            callback({
              success: false,
              message: 'There was an error creating the event.',
            });
          } else {
            if (event && oGAppointmentType === 'Hold') {
              deleteEvent(event);
            }
            callback({
              success: true,
              message: 'Event created successfully.',
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    if (selectedDate !== null) {
      const dt = new Date(selectedDate.date.getTime());
      setDate(moment(dt));
      setStartTime(moment(dt).toDate());
      setEndTime(moment(dt).add(1, 'hour').toDate());
    }
  }, [selectedDate]);

  useEffect(() => {
    const getFilteredVeterinarians = () => {
      get({
        endpoint: `veterinarians?deleted=false&practiceName=${veterinarianSearchText}&order%5BpracticeName%5D=asc${
          appointmentType === 'CAC' ? '&cac=true' : ''
        }`,
      }).then(async response => {
        const veterinariansJson = await response.json();
        setVeterinarians(veterinariansJson['hydra:member']);
      });
    };

    getFilteredVeterinarians();
  }, [appointmentType, veterinarianSearchText]);

  useEffect(() => {
    const getMemorialOptions = () => {
      get({
        endpoint: `memorial_options?deleted=false`,
      }).then(async response => {
        const memorialOptionsJson = await response.json();
        setMemorialOptions(memorialOptionsJson['hydra:member']);
      });
    };

    getMemorialOptions();
  }, []);

  useEffect(() => {
    const getFilteredDoctors = () => {
      get({
        endpoint: `doctor_lists?deleted=false&searchTerm=${doctorSearchText}`,
      }).then(async response => {
        const doctorsJson = await response.json();
        setDoctors(
          doctorsJson['hydra:member'].filter((dr: Doctor) => dr !== null),
        );
      });
    };

    getFilteredDoctors();
  }, [doctorSearchText]);

  useEffect(() => {
    const getFilteredAftercares = () => {
      get({
        endpoint: `aftercares?deleted=false&code=${aftercareSearchText}`,
      }).then(async response => {
        const aftercaresJson = await response.json();
        setAftercares(aftercaresJson['hydra:member']);
      });
    };

    getFilteredAftercares();
  }, [aftercareSearchText]);

  useEffect(() => {
    const getFilteredAftercareLocations = () => {
      get({
        endpoint: `aftercare_locations?deleted=false&name=${aftercareLocationSearchText}${
          appointmentType === 'CAC' ? '&cac=true' : ''
        }`,
      }).then(async response => {
        const aftercareLocationsJson = await response.json();
        await setAftercareLocations(aftercareLocationsJson['hydra:member']);
        const def = aftercareLocationsJson['hydra:member'].filter(
          (_al: { def: boolean }) => _al.def,
        );
        if (type !== 'edit' && def.length > 0) {
          setAftercareLocation(def[0]);
        }
      });
    };

    getFilteredAftercareLocations();
  }, [aftercareLocationSearchText, appointmentType, type]);

  useEffect(() => {
    const getFilteredDiagnosis = () => {
      get({
        endpoint: `diagnoses?deleted=false&description=${diagnosisSearchText}`,
      }).then(async response => {
        const diagnosisJson = await response.json();
        setDiagnosisArr(diagnosisJson['hydra:member']);
      });
    };

    getFilteredDiagnosis();
  }, [diagnosisSearchText]);
  useEffect(() => {
    if (event !== undefined) {
      const dts = new Date(event.startTime);
      setDate(moment(dts));
      setStartTime(dts);
      setEndTime(new Date(event.endTime));

      setAppointmentType(getAppointmentTypeById(event['@type']));
      setOGAppointmentType(getAppointmentTypeById(event['@type']));
      setPet(event.pet ?? null);

      setClient(event.owner ?? null);
      setTopic(event.description);
      setAftercare(event.aftercare ?? null);
      setAftercareCharge(event.aftercareCharge ?? 0.0);
      setDoctorPayment(event.doctorPayment ?? 0.0);
      setCrematoryPayment(event.crematoryPayment ?? 0.0);
      setAftercareLocation(event.aftercareLocation ?? null);
      setAftercareStatus(event.aftercareStatus ?? null);
      setConsultationCharge(event.consultationCharge);
      setContactPreference(event.contactPreference);
      setDiagnosis(event.pet?.diagnosis ?? null);
      setDoctor(event.user ?? null);
      setTransportOnly(event.transport ?? false);
      setLateCancel(event.lateCancelation ?? false);
      setSendEmail(true);
      setEuthanasiaCharge(event.euthanasiaCharge ?? 0.0);
      setReferral(event.pet?.referral ?? '');
      setClayPaws(
        getPetMemorialOption(event.pet?.memorialOptions, 'CP').amount,
      );
      setInkPaws(getPetMemorialOption(event.pet?.memorialOptions, 'IP').amount);
      setNosePrints(
        getPetMemorialOption(event.pet?.memorialOptions, 'NP').amount,
      );
      if (
        event.pet &&
        event.pet.veterinarians &&
        event.pet.veterinarians.length > 0
      ) {
        setSelectedVeterinarians(event.pet.veterinarians);
      }
    }
  }, [event]);

  useEffect(() => {
    const localEvent: Event = {
      id: event ? event.id : '',
      description: topic,
      startTime: date
        ?.set({
          hour: startTime?.getHours(),
          minute: startTime?.getMinutes(),
          second: startTime?.getSeconds(),
        })
        .format('MM/DD/YYYY HH:mm:ss') as string,
      endTime: date
        ?.set({
          hour: endTime?.getHours(),
          minute: endTime?.getMinutes(),
          second: endTime?.getSeconds(),
        })
        .format('MM/DD/YYYY HH:mm:ss') as string,
      allDay: !!selectedDate?.allDay,
      transport: transportOnly,
      lateCancelation: lateCancel,
      mailing: sendEmail,
      witness: false,
      other: false,
      contactPreference: contactPreference,
      consultationCharge: consultationCharge,
      user: doctor === null ? undefined : doctor,
      owner: client === null ? undefined : client,
      pet:
        pet === null
          ? undefined
          : {
              ...pet,
              veterinarians:
                selectedVeterinarians === null ? [] : selectedVeterinarians,
              referral: referral,
              diagnosis: diagnosis === null ? undefined : diagnosis,
              petNotes:
                petNote !== '<p></p>'
                  ? [
                      ...(pet && pet?.petNotes !== undefined
                        ? pet?.petNotes
                        : []),
                      {
                        note: petNote,
                        noteDateTime: new Date().toISOString(),
                        noteInitials: '',
                        addedBy: '',
                      },
                    ]
                  : [
                      ...(pet && pet?.petNotes !== undefined
                        ? pet?.petNotes
                        : []),
                    ],
              memorialOptions: [
                {
                  amount: clayPaws,
                  memorialOption: getMemorialOption(memorialOptions, 'CP'),
                  id: event
                    ? getPetMemorialOption(event.pet?.memorialOptions, 'CP').id
                    : '',
                },
                {
                  amount: inkPaws,
                  memorialOption: getMemorialOption(memorialOptions, 'IP'),
                  id: event
                    ? getPetMemorialOption(event.pet?.memorialOptions, 'IP').id
                    : '',
                },
                {
                  amount: nosePrints,
                  memorialOption: getMemorialOption(memorialOptions, 'NP'),
                  id: event
                    ? getPetMemorialOption(event.pet?.memorialOptions, 'NP').id
                    : '',
                },
              ],
            },
      aftercareLocation:
        aftercareLocation === null ? undefined : aftercareLocation,
      aftercare: aftercare === null ? undefined : aftercare,
      aftercareCharge: aftercareCharge,
      otherCharge: 0.0,
      otherChargeDescription: '',
      euthanasiaCharge: euthanasiaCharge,
      transportCharge: 0.0,
      aftercareStatus: aftercareStatus ?? undefined,
      crematoryExtraCharge: 0.0,
      doctorPayment: doctorPayment,
      crematoryPayment: crematoryPayment,
      veterinarian: selectedVeterinarians
        ? selectedVeterinarians[0]
        : undefined,
      petNewNote: petNote && petNote !== '<p></p>' ? petNote : undefined,
      '@type': selectedDate?.allDay ? 'Duty' : appointmentType,
    };
    setFinalEvent(localEvent);
  }, [
    aftercare,
    aftercareCharge,
    aftercareLocation,
    aftercareStatus,
    appointmentType,
    clayPaws,
    client,
    consultationCharge,
    contactPreference,
    crematoryPayment,
    date,
    diagnosis,
    doctor,
    doctorPayment,
    endTime,
    euthanasiaCharge,
    event,
    inkPaws,
    memorialOptions,
    nosePrints,
    pet,
    petNote,
    referral,
    selectedDate,
    sendEmail,
    startTime,
    topic,
    transportOnly,
    lateCancel,
    selectedVeterinarians,
  ]);
  useEffect(() => {
    if (
      appointmentType === 'Duty' ||
      appointmentType === 'Hold' ||
      appointmentType === 'Break'
    ) {
      if (
        topic === '' ||
        startTime === null ||
        endTime === null ||
        date === null
      ) {
        setNotValid(true);
      } else {
        setNotValid(false);
      }
    } else {
      if (appointmentType === 'CAC') {
        if (
          topic === '' ||
          startTime === null ||
          endTime === null ||
          client === null ||
          client.lastname.trim() === '' ||
          pet === null ||
          pet.name.trim() === '' ||
          date === null
        ) {
          setNotValid(true);
        } else {
          setNotValid(false);
        }
      } else {
        if (appointmentType === 'Aftercare') {
          if (
            topic === '' ||
            startTime === null ||
            endTime === null ||
            client === null ||
            client.lastname.trim() === '' ||
            pet === null ||
            pet.name.trim() === '' ||
            date === null
          ) {
            setNotValid(true);
          } else {
            setNotValid(false);
          }
        } else {
          if (
            topic === '' ||
            startTime === null ||
            endTime === null ||
            client === null ||
            client.lastname.trim() === '' ||
            pet === null ||
            pet.name.trim() === '' ||
            date === null
          ) {
            setNotValid(true);
          } else {
            setNotValid(false);
          }
        }
      }
    }
  }, [
    endTime,
    startTime,
    topic,
    client,
    selectedVeterinarians,
    appointmentType,
    pet,
    doctor,
    aftercare,
    aftercareLocation,
    date,
    diagnosis,
  ]);
  useEffect(() => {
    if (pet && pet.diagnosis) {
      setDiagnosis(pet.diagnosis);
    }
    if (pet && pet.referral) {
      setReferral(pet.referral);
    }

    if (
      appointmentType !== 'CAC' &&
      pet &&
      pet.veterinarians &&
      pet.veterinarians.length > 0
    ) {
      setSelectedVeterinarians(pet.veterinarians);
    }
    // if (pet && pet.veterinarians) {
    //   setSelectedVeterinarians(pet?.veterinarians ? pet?.veterinarians : []);
    // }
  }, [appointmentType, pet]);
  return (
    <ModalDialog
      title={
        type === 'create' ? 'Create New Appointment' : 'Update Appointment'
      }
      closeCallback={closeCallback}
      headerColor={'#CFCFCF'}
      buttonText={type === 'create' ? 'Schedule' : 'Save'}
      actionCallback={saveAppointment}
      buttonEnabled={notValid}
    >
      <TabbedShowLayout>
        <Tab label={'Details'} contentClassName={classes.tabContent}>
          <InputLabel className={classes.firstItem}>Date</InputLabel>
          <SingleDatePicker
            date={date}
            onDateChange={date => setDate(date)}
            focused={goToDateFocus}
            onFocusChange={({ focused }) => setGoToDateFocus(focused)}
            displayFormat="MMM-DD-YYYY"
            id={'goToDate'}
            numberOfMonths={1}
            isOutsideRange={() => false}
            showDefaultInputIcon
            inputIconPosition="after"
          />
          <div className={classes.container}>
            <div className={classes.timeContainer}>
              <InputLabel className={classes.label}>Start Time</InputLabel>
              <DatePicker
                selected={startTime}
                onChange={date => setStartTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Start Time"
                dateFormat="h:mm aa"
              />
            </div>
            <div className={classes.timeContainer}>
              <InputLabel className={classes.label}>End Time</InputLabel>
              <DatePicker
                selected={endTime}
                onChange={date => setEndTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="End Time"
                dateFormat="h:mm aa"
              />
            </div>
          </div>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel className={classes.label}>Topic</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={topic}
              onChange={e => setTopic(e.target.value as string)}
              variant="outlined"
            />
          </FormControl>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel className={classes.label}>Appointment Type</InputLabel>
            <Autocomplete
              id="combo-box-demo"
              disabled={
                selectedDate?.allDay ||
                (type === 'edit' && oGAppointmentType !== 'Hold')
              }
              options={['Euthanasia', 'Aftercare', 'CAC', 'Hold', 'Break']}
              renderInput={params => <TextField {...params} />}
              onChange={(evt, value) => setAppointmentType(value as string)}
              value={appointmentType}
            />
          </FormControl>
          {(appointmentType === 'Euthanasia' ||
            appointmentType === 'Aftercare') && (
            <>
              <ClientSelectBundle
                selectedClient={client}
                onChangeCallback={setClient}
                disabled={type === 'edit' && oGAppointmentType !== 'Hold'}
              />
              <PetSelectBundle
                selectedPet={pet}
                hidePlus={client?.id === ''}
                onChangeCallback={setPet}
                selectedClient={client && client.id !== '' ? client : undefined}
                disabled={type === 'edit' && oGAppointmentType !== 'Hold'}
              />
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Transport Only"
                  className={classes.label}
                  value={transportOnly}
                  checked={transportOnly}
                  onChange={(ev, checked) => setTransportOnly(checked)}
                />
              </FormControl>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Late Cancellation"
                  className={classes.label}
                  value={lateCancel}
                  checked={lateCancel}
                  onChange={(ev, checked) => setLateCancel(checked)}
                />
              </FormControl>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel className={classes.label}>Veterinarians</InputLabel>
                <Autocomplete
                  multiple={true}
                  id="vet-select-modal"
                  options={veterinarians}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Search Veterinarians"
                      onChange={evt =>
                        setVeterinarianSearchText(evt.target.value as string)
                      }
                    />
                  )}
                  getOptionLabel={option => option.practiceName.toString()}
                  onChange={(evt, value) => setSelectedVeterinarians(value)}
                  value={selectedVeterinarians ?? []}
                />
              </FormControl>
              {appointmentType === 'Euthanasia' && (
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel className={classes.label}>CC Doctor</InputLabel>
                  <Autocomplete
                    id="vet-select-modal"
                    options={doctors}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Search Doctors"
                        onChange={evt =>
                          setDoctorSearchText(evt.target.value as string)
                        }
                      />
                    )}
                    getOptionLabel={option => option.name.toString()}
                    onChange={(evt, value) => setDoctor(value)}
                    value={doctor}
                  />
                </FormControl>
              )}
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel className={classes.label}>Aftercare</InputLabel>
                <Autocomplete
                  id="aftercare-select-modal"
                  options={aftercares}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Select Code"
                      onChange={evt =>
                        setAftercareSearchText(evt.target.value as string)
                      }
                    />
                  )}
                  getOptionLabel={option => option.code.toString()}
                  onChange={(evt, value) => setAftercare(value)}
                  value={aftercare}
                />
              </FormControl>
              {(appointmentType === 'Aftercare' ||
                appointmentType === 'Euthanasia') && (
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel className={classes.label}>
                    Aftercare Location
                  </InputLabel>
                  <Autocomplete
                    id="crematory-select-modal"
                    options={aftercareLocations}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Select Location"
                        onChange={evt =>
                          setAftercareLocationSearchText(
                            evt.target.value as string,
                          )
                        }
                      />
                    )}
                    getOptionLabel={option => option.name.toString()}
                    onChange={(evt, value) => setAftercareLocation(value)}
                    value={aftercareLocation ? aftercareLocation : ccMlk}
                  />
                </FormControl>
              )}

              <div className={classes.twoDivided}>
                <FormControl
                  variant="outlined"
                  className={classes.dividedField}
                >
                  <InputLabel className={classes.label}>
                    Consultation Charge
                  </InputLabel>
                  <TextField
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={consultationCharge}
                    onChange={e => {
                      if (Number(e.target.value) < 0) {
                        e.target.value = '0';
                      }
                      setConsultationCharge(Number(e.target.value));
                    }}
                    variant="outlined"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
                {appointmentType === 'Euthanasia' && (
                  <FormControl
                    variant="outlined"
                    className={classes.dividedField}
                  >
                    <InputLabel className={classes.label}>
                      Euthanasia Charge
                    </InputLabel>
                    <TextField
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={euthanasiaCharge}
                      onChange={e => {
                        if (Number(e.target.value) < 0) {
                          e.target.value = '0';
                        }
                        setEuthanasiaCharge(Number(e.target.value));
                      }}
                      variant="outlined"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                    />
                  </FormControl>
                )}
              </div>
              <div className={classes.twoDivided}>
                <FormControl
                  variant="outlined"
                  className={classes.dividedField}
                >
                  <InputLabel className={classes.label}>
                    Aftercare Charge
                  </InputLabel>
                  <TextField
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={aftercareCharge}
                    onChange={e => {
                      if (Number(e.target.value) < 0) {
                        e.target.value = '0';
                      }
                      setAftercareCharge(Number(e.target.value));
                    }}
                    variant="outlined"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
                <div className={classes.twoDividedColumn}>
                  <InputLabel
                    className={classes.label}
                    style={{ width: '100%' }}
                  >
                    Add-Ons (Quantity)
                  </InputLabel>
                  <div className={classes.twoDivided}>
                    <Typography variant="body1" className={classes.addOnLabel}>
                      CP{' '}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.tinyDividedField}
                    >
                      <TextField
                        type="number"
                        className={classes.numberInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={clayPaws}
                        onChange={e => {
                          setClayPaws(Number(e.target.value));
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                    <Typography variant="body1" className={classes.addOnLabel}>
                      IP{' '}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.tinyDividedField}
                    >
                      <TextField
                        type="number"
                        className={classes.numberInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={inkPaws}
                        onChange={e => {
                          setInkPaws(Number(e.target.value));
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                    <Typography variant="body1" className={classes.addOnLabel}>
                      NP{' '}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.tinyDividedField}
                    >
                      <TextField
                        type="number"
                        className={classes.numberInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={nosePrints}
                        onChange={e => {
                          setNosePrints(Number(e.target.value));
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className={classes.twoDivided}>
                <FormControl
                  variant="outlined"
                  className={classes.dividedField}
                >
                  <InputLabel className={classes.label}>Referral</InputLabel>
                  <Select
                    className={classes.referralBorder}
                    id="referral"
                    placeholder={'Select Referral'}
                    value={referral}
                    onChange={e => setReferral(e.target.value as string)}
                  >
                    {referralMap.map(_ref => {
                      return (
                        <MenuItem key={_ref.id} value={_ref.id}>
                          {_ref.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.dividedField}
                >
                  <InputLabel className={classes.label}>Diagnosis</InputLabel>
                  <Autocomplete
                    id="diagnosis-select-modal"
                    options={diagnosisArr}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Select Diagnosis"
                        onChange={evt =>
                          setDiagnosisSearchText(evt.target.value as string)
                        }
                      />
                    )}
                    getOptionLabel={option => option.description.toString()}
                    onChange={(evt, value) => setDiagnosis(value)}
                    value={diagnosis}
                  />
                </FormControl>
              </div>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel className={classes.label}>Pet Notes</InputLabel>
                <Editor
                  editorState={editorState}
                  spellCheck={true}
                  onEditorStateChange={onEditorStateChange}
                  editorStyle={{
                    maxHeight: '150px',
                    paddingLeft: '10px',
                    paddingRight: '110px',
                    maxWidth: '540px',
                    minHeight: '80px',
                  }}
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'list',
                      'textAlign',
                      'colorPicker',
                      'link',
                      'emoji',
                    ],
                    textAlign: {
                      inDropdown: true,
                    },
                    inline: {
                      inDropdown: true,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ['bold', 'italic', 'underline', 'monospace'],
                    },
                    list: {
                      inDropdown: true,
                    },
                    blockType: {
                      inDropdown: true,
                      options: [
                        'Normal',
                        'H1',
                        'H2',
                        'H3',
                        'H4',
                        'H5',
                        'H6',
                        'Blockquote',
                        // 'Code',
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                  }}
                />
                {/*<TextareaAutosize*/}
                {/*  className={classes.petNotes}*/}
                {/*  defaultValue={petNote}*/}
                {/*  rowsMax={5}*/}
                {/*  onChange={e => {*/}
                {/*    setPetNote(e.target.value as string);*/}
                {/*  }}*/}
                {/*/>*/}
              </FormControl>
              <FormControl style={{ width: '100%' }}>
                <InputLabel className={classes.label}>
                  Contact Preference
                </InputLabel>
                <Select
                  className='="contact-select'
                  id="contact-select-modal"
                  value={contactPreference}
                  onChange={e => setContactPreference(e.target.value as string)}
                >
                  <MenuItem value={'Text'}>Text</MenuItem>
                  <MenuItem value={'Call'}>Call</MenuItem>
                  <MenuItem value={'None'}>None</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Send email notification"
                  value={sendEmail}
                  checked={sendEmail}
                  onChange={(ev, checked) => setSendEmail(checked)}
                />
              </FormControl>
            </>
          )}
          {appointmentType === 'CAC' && (
            <>
              <ClientSelectBundle
                selectedClient={client}
                onChangeCallback={setClient}
                disabled={type === 'edit' && oGAppointmentType !== 'Hold'}
              />
              <PetSelectBundle
                selectedPet={pet}
                onChangeCallback={setPet}
                selectedClient={client ?? undefined}
                disabled={type === 'edit' && oGAppointmentType !== 'Hold'}
              />
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel className={classes.label}>CAC Clinic</InputLabel>
                <Autocomplete
                  id="vet-select-modal"
                  options={veterinarians}
                  multiple={false}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Search Clinics"
                      onChange={evt =>
                        setVeterinarianSearchText(evt.target.value as string)
                      }
                    />
                  )}
                  getOptionLabel={option => option.practiceName.toString()}
                  onChange={(evt, value) =>
                    setSelectedVeterinarians(value ? [value] : [])
                  }
                  value={
                    selectedVeterinarians ? selectedVeterinarians[0] : null
                  }
                />
              </FormControl>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel className={classes.label}>Aftercare</InputLabel>
                <Autocomplete
                  id="aftercare-select-modal"
                  options={aftercares}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Select Code"
                      onChange={evt =>
                        setAftercareSearchText(evt.target.value as string)
                      }
                    />
                  )}
                  getOptionLabel={option => option.code.toString()}
                  onChange={(evt, value) => setAftercare(value)}
                  value={aftercare}
                />
              </FormControl>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel className={classes.label}>
                  Aftercare Location
                </InputLabel>
                <Autocomplete
                  id="crematory-select-modal"
                  options={aftercareLocations}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Select Location"
                      onChange={evt =>
                        setAftercareLocationSearchText(
                          evt.target.value as string,
                        )
                      }
                    />
                  )}
                  getOptionLabel={option => option.name.toString()}
                  onChange={(evt, value) => setAftercareLocation(value)}
                  value={aftercareLocation ? aftercareLocation : ccMlk}
                />
              </FormControl>
              <div className={classes.twoDivided}>
                <FormControl
                  variant="outlined"
                  className={classes.dividedField}
                >
                  <InputLabel className={classes.label}>
                    Aftercare Charge
                  </InputLabel>
                  <TextField
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={aftercareCharge}
                    onChange={e => {
                      setAftercareCharge(Number(e.target.value));
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <div className={classes.twoDividedColumn}>
                  <InputLabel
                    className={classes.label}
                    style={{ width: '100%' }}
                  >
                    Add-Ons (Quantity)
                  </InputLabel>
                  <div className={classes.twoDivided}>
                    <Typography variant="body1" className={classes.addOnLabel}>
                      CP{' '}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.tinyDividedField}
                    >
                      <TextField
                        type="number"
                        className={classes.numberInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={clayPaws}
                        onChange={e => {
                          setClayPaws(Number(e.target.value));
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                    <Typography variant="body1" className={classes.addOnLabel}>
                      IP{' '}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.tinyDividedField}
                    >
                      <TextField
                        type="number"
                        className={classes.numberInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={inkPaws}
                        onChange={e => {
                          setInkPaws(Number(e.target.value));
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                    <Typography variant="body1" className={classes.addOnLabel}>
                      NP{' '}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.tinyDividedField}
                    >
                      <TextField
                        type="number"
                        className={classes.numberInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={nosePrints}
                        onChange={e => {
                          setNosePrints(Number(e.target.value));
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Send email notification"
                  value={sendEmail}
                  checked={sendEmail}
                  onChange={(ev, checked) => setSendEmail(checked)}
                />
              </FormControl>
            </>
          )}
        </Tab>
        <Tab
          label={'Charges'}
          disabled={
            appointmentType === 'Duty' ||
            appointmentType === 'Hold' ||
            appointmentType === 'Break'
          }
        >
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Consultation Charge
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              ${consultationCharge}
            </Typography>
          </div>
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Euthanasia Charge
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              ${euthanasiaCharge}
            </Typography>
          </div>
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Crematory Facility
            </Typography>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <Autocomplete
                disabled
                id="crematory-select-modal"
                options={aftercareLocations}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={evt =>
                      setAftercareLocationSearchText(evt.target.value as string)
                    }
                  />
                )}
                getOptionLabel={option => option.name.toString()}
                onChange={(evt, value) => setAftercareLocation(value)}
                value={aftercareLocation}
              />
            </FormControl>
          </div>
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Aftercare Base Charge
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              ${aftercareCharge}
            </Typography>
          </div>
          <Typography variant="body1">Add-ons(Quantity)</Typography>
          <div className={`${classes.twoDivided} ${classes.afters}`}>
            <Typography style={{ marginRight: '18px' }} variant="body1">
              CP{' '}
            </Typography>
            <Typography style={{ marginRight: '18px' }} variant="body1">
              {' '}
              {clayPaws}
            </Typography>
            <Typography style={{ marginRight: '18px' }} variant="body1">
              {' '}
              IP{' '}
            </Typography>
            <Typography style={{ marginRight: '18px' }} variant="body1">
              {' '}
              {inkPaws}
            </Typography>
            <Typography style={{ marginRight: '18px' }} variant="body1">
              {' '}
              NP{' '}
            </Typography>
            <Typography style={{ marginRight: '18px' }} variant="body1">
              {' '}
              {nosePrints}
            </Typography>
          </div>
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Total Aftercare Charge
            </Typography>
            <Typography variant="body1" className={classes.textRight}>
              ${getTotalAftercareCharge(event)}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#000' }} />
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Doctor Payment
            </Typography>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={doctorPayment}
              onChange={e => {
                setDoctorPayment(Number(e.target.value));
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.twoDivided}>
            <Typography variant="body1" className={classes.textLeft}>
              Crematory Payment
            </Typography>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={crematoryPayment}
              onChange={e => {
                setCrematoryPayment(Number(e.target.value));
              }}
              variant="outlined"
            />
          </div>
        </Tab>
      </TabbedShowLayout>
      <AlertDialog
        message={
          'This appointment is more than 7 days in advance of the current date. Would you like to proceed?'
        }
        title={'Verify event'}
        open={alertOpen}
        accept={completeSave}
        cancel={() => setAlertOpen(false)}
      />
    </ModalDialog>
  );
};
