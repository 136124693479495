import React, { FC, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Doctor } from '../shared/types';
import { ReportsProvider } from './useReportContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { get, post } from '../../utils/Api';
import { Moment } from 'moment';
import Button from '@material-ui/core/Button';
import { downloadDoc } from '../../utils/StringUtils';
import { Notification } from '../shared/Notification';

const baseStyles = makeStyles({
  reportContainer: {
    marginTop: '98px',
    marginLeft: '35px',
    '&DateInput_input DateInput_input_1': {
      backgroundColor: '#0000000D',
    },
  },
  label: {
    marginBottom: '10.19px',
    marginTop: '22px',
  },
  SingleDatePicker: {
    marginRight: '20px',
  },
  button: {
    marginTop: '40px',
    position: 'relative',
    padding: '10px 36px',
  },
});

const Report: FC = () => {
  const [doctor, setDoctor] = useState<Doctor | null>(null);
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [doctorSearchText, setDoctorSearchText] = useState<string>('');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [goToStartDateFocus, setGoToStartDateFocus] = useState(false);
  const [goToEndDateFocus, setGoToEndDateFocus] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);

  const classes = baseStyles();

  const getReport = async () => {
    const file = await post({
      endpoint: 'reports/pay-slip',
      body: JSON.stringify({
        startDate: startDate?.format('Y-M-D') + 'T00:00:00',
        endDate: endDate?.format('Y-M-D') + 'T23:59:59',
        doctor: doctor?.id,
      }),
    });
    if (file.ok) {
      await downloadDoc(file, 'pay-slip.docx');
    } else {
      setSuccess(false);
      setSnackbarMessage('There was an error generating the report.');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (doctor !== null && startDate !== null && endDate !== null) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [doctor, endDate, startDate]);

  useEffect(() => {
    const getFilteredDoctors = () => {
      get({
        endpoint: `doctor_lists?deleted=false&searchTerm=${doctorSearchText}&order%5Bname%5D=asc`,
      }).then(async response => {
        const doctorsJson = await response.json();
        setDoctors(
          doctorsJson['hydra:member'].filter((dr: Doctor) => dr !== null),
        );
      });
    };

    getFilteredDoctors();
  }, [doctorSearchText]);
  return (
    <>
      <div className={classes.reportContainer}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Pay Slip Report
        </Typography>
        <FormControl variant="outlined" style={{ width: '250px' }}>
          <InputLabel className={classes.label}>CC Doctor</InputLabel>
          <Autocomplete
            id="vet-select-modal"
            options={doctors}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Search Doctors"
                onChange={evt =>
                  setDoctorSearchText(evt.target.value as string)
                }
              />
            )}
            getOptionLabel={option => option.name.toString()}
            onChange={(evt, value) => setDoctor(value)}
            value={doctor}
          />
        </FormControl>
        <InputLabel className={classes.label}>Date Range</InputLabel>
        <SingleDatePicker
          date={startDate}
          onDateChange={date => setStartDate(date)}
          focused={goToStartDateFocus}
          onFocusChange={({ focused }) => setGoToStartDateFocus(focused)}
          displayFormat="MMM-DD-YYYY"
          id={'goToDate'}
          numberOfMonths={1}
          isOutsideRange={() => false}
          placeholder="Start Date"
        />
        <SingleDatePicker
          date={endDate}
          onDateChange={date => setEndDate(date)}
          focused={goToEndDateFocus}
          onFocusChange={({ focused }) => setGoToEndDateFocus(focused)}
          displayFormat="MMM-DD-YYYY"
          id={'goToDate'}
          numberOfMonths={1}
          isOutsideRange={() => false}
          placeholder="End Date"
        />
        <div>
          <Button
            disabled={!buttonEnabled}
            variant="contained"
            className={classes.button}
            onClick={getReport}
          >
            Generate Report
          </Button>
        </div>
      </div>
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </>
  );
};

export const PayReport: FC = () => (
  <ReportsProvider>
    <Report />
  </ReportsProvider>
);
