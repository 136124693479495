import React, { FC, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

import { get, post } from '../../utils/Api';
import { Moment } from 'moment';
import Button from '@material-ui/core/Button';
import { downloadCSV } from '../../utils/StringUtils';
import { Notification } from '../shared/Notification';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { User } from '../shared/types';

const baseStyles = makeStyles({
  reportContainer: {
    marginTop: '98px',
    marginLeft: '35px',
    '&DateInput_input DateInput_input_1': {
      backgroundColor: '#0000000D',
    },
  },
  label: {
    marginBottom: '10.19px',
    marginTop: '22px',
  },
  SingleDatePicker: {
    marginRight: '20px',
  },
  button: {
    marginTop: '40px',
    position: 'relative',
    padding: '10px 36px',
  },
  checks: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDropdownInput: {
    maxWidth: '250px',
  },
});

export const ScorecardDataReport: FC = () => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [goToStartDateFocus, setGoToStartDateFocus] = useState(false);
  const [goToEndDateFocus, setGoToEndDateFocus] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [employees, setEmployees] = useState<User[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<string>('');

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);

  const classes = baseStyles();

  const getReport = async () => {
    const file = await post({
      endpoint: 'reports/scorecard',
      body: JSON.stringify({
        startDate: startDate?.format('Y-M-D') + 'T00:00:00',
        endDate: endDate?.format('Y-M-D') + 'T23:59:59',
        employee: selectedEmployee,
      }),
    });
    if (file.ok) {
      await downloadCSV(file, 'scorecardData.csv');
    } else {
      setSuccess(false);
      setSnackbarMessage('There was an error generating the report.');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    const getFilteredUsers = () => {
      get({
        endpoint: `users?deleted=false&itemsPerPage=1000&order%5Bname%5D=asc`,
      }).then(async response => {
        const employeesJson = await response.json();
        setEmployees(
          employeesJson['hydra:member'].filter((usr: User) => usr !== null),
        );
      });
    };

    getFilteredUsers();
  }, []);

  useEffect(() => {
    if (startDate !== null && endDate !== null && employees.length > 0) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [employees, endDate, startDate]);

  return (
    <>
      <div className={classes.reportContainer}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Scorecard Data Export
        </Typography>
        <InputLabel className={classes.label}>Date Range</InputLabel>
        <SingleDatePicker
          date={startDate}
          onDateChange={date => setStartDate(date)}
          focused={goToStartDateFocus}
          onFocusChange={({ focused }) => setGoToStartDateFocus(focused)}
          displayFormat="MMM-DD-YYYY"
          id={'goToDate'}
          numberOfMonths={1}
          isOutsideRange={() => false}
          placeholder="Start Date"
        />
        <SingleDatePicker
          date={endDate}
          onDateChange={date => setEndDate(date)}
          focused={goToEndDateFocus}
          onFocusChange={({ focused }) => setGoToEndDateFocus(focused)}
          displayFormat="MMM-DD-YYYY"
          id={'goToDate'}
          numberOfMonths={1}
          isOutsideRange={() => false}
          placeholder="End Date"
        />
        <InputLabel className={classes.label}>Employees</InputLabel>
        <div className={classes.checks}>
          <Select
            labelId="period-selector-label"
            id="period-selector"
            value={selectedEmployee}
            onChange={event => {
              setSelectedEmployee(event.target.value as string);
            }}
            input={<Input />}
            className={classes.mobileDropdownInput}
          >
            {employees.map(user => {
              return (
                <MenuItem key={user.id} value={user.id}>
                  <span>{user.name}</span>
                </MenuItem>
              );
            })}
          </Select>
        </div>

        <div>
          <Button
            disabled={!buttonEnabled}
            variant="contained"
            className={classes.button}
            onClick={getReport}
          >
            Generate Report
          </Button>
        </div>
      </div>
      <Notification
        close={() => {
          setOpenSnackbar(false);
        }}
        message={snackbarMessage}
        open={openSnackbar}
        success={success}
      />
    </>
  );
};
