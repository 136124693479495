import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { City, Client, Consumer, State } from './types';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { get } from '../../utils/Api';
import { Button } from 'react-admin';
import { formatPhoneNumber } from '../../utils/StringUtils';

const styles = makeStyles({
  twoDivided: {
    display: 'flex',
  },
  threeDivided: {
    display: 'flex',
  },
  dividedField: {
    width: '158px',
    marginRight: '16px',
  },
  singleField: {
    width: '100%',
  },
  label: {
    marginBottom: '10.19px',
    marginTop: '22px',
  },
});
interface TopRightActionsProps {
  selectedClient: Client | null;
  onChangeCallback: Consumer<Client | null>;
  hidePlus?: boolean;
  disabled?: boolean;
}

export const ClientSelectBundle: React.FC<TopRightActionsProps> = ({
  selectedClient,
  onChangeCallback,
  hidePlus,
  disabled,
}) => {
  const [options, setOptions] = useState<Client[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [stateSearchText, setStateSearchText] = useState<string>('');
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [citySearchText, setCitySearchText] = useState<string>('');
  const [newClient, setNewClient] = useState<boolean>(false);
  const [newClientLabel, setNewClientLabel] = useState<string>('+ New Client');
  const [client, setClient] = useState<Client | null>(null);
  const [firstname, setFirstname] = useState<string>(
    selectedClient ? selectedClient.firstname : '',
  );
  const [lastname, setLastname] = useState<string>(
    selectedClient ? selectedClient.lastname : '',
  );
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const classes = styles();
  useEffect(() => {
    setClient(selectedClient);
  }, [selectedClient]);
  useEffect(() => {
    if (client) {
      onChangeCallback(client);
    }
  }, [client, onChangeCallback]);
  useEffect(() => {
    const getFilteredClients = () => {
      get({
        endpoint: `owners?deleted=false&searchTerm=${searchText}`,
      }).then(async response => {
        const clientsJson = await response.json();
        setOptions(clientsJson['hydra:member']);
      });
    };

    getFilteredClients();
  }, [searchText]);
  useEffect(() => {
    const getStates = () => {
      get({
        endpoint: `states?deleted=false&searchTerm=${stateSearchText}&itemsPerPage=100`,
      }).then(async response => {
        const statesJson = await response.json();
        setStates(statesJson['hydra:member']);
      });
    };

    getStates();
  }, [stateSearchText]);

  useEffect(() => {
    const getCities = () => {
      get({
        endpoint: `cities?deleted=false&state.id=${selectedState?.id}&name=${citySearchText}&itemsPerPage=2000`,
      }).then(async response => {
        const citiesJson = await response.json();
        setCities(citiesJson['hydra:member']);
      });
    };
    if (selectedState !== null) {
      getCities();
    }
  }, [citySearchText, selectedState, stateSearchText]);

  useEffect(() => {
    if (hidePlus) {
      newClientClean();
    }
  }, [hidePlus]);

  useEffect(() => {
    if (client) {
      onChangeCallback(client);
    }
  }, [client, onChangeCallback]);
  useEffect(() => {
    if (selectedClient) {
      const localClient: Client = {
        id: selectedClient ? selectedClient.id : '',
        firstname: firstname,
        lastname: lastname,
        telephone: phoneNumber,
        alternateTelephone: secondaryPhoneNumber,
        email: email,
        primary: true,
        addressLine1: addressLine1,
        location: location,
        city: selectedCity?.name,
        state: selectedState?.code,
        postalCode: postalCode,
        quadrant: '',
        ownerNotes: [],
        households: [],
        events: [],
        pets: [],
      };
      setClient(localClient);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [
    addressLine1,
    location,
    email,
    firstname,
    lastname,
    phoneNumber,
    postalCode,
    secondaryPhoneNumber,
    selectedCity,
    selectedState,
  ]);
  const newClientClean = () => {
    setNewClientLabel('- New Client');
    const localClient: Client = {
      id: '',
      firstname: '',
      lastname: '',
      telephone: '',
      alternateTelephone: '',
      email: '',
      primary: true,
      addressLine1: '',
      location: '',
      city: '',
      state: '',
      postalCode: '',
      quadrant: '',
      ownerNotes: [],
      households: [],
      events: [],
      pets: [],
    };
    setClient(localClient);
    setNewClient(true);
  };

  const [query, setQuery] = useState('');

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    <div style={{ marginBottom: '20px' }}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel className={classes.label}>Client Name</InputLabel>
        <Autocomplete
          disabled={newClient || disabled}
          id="client-select-modal"
          options={options}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Search Clients"
              onChange={evt => setQuery(evt.target.value as string)}
            />
          )}
          getOptionLabel={option =>
            option.firstname.toString() +
            ' ' +
            option.lastname.toString() +
            ' - ' +
            option.telephone +
            ' - ' +
            option.alternateTelephone +
            ' - ' +
            option.email
          }
          onChange={(evt, value) => setClient(value)}
          value={client}
        />
      </FormControl>
      <Button
        disabled={hidePlus || disabled}
        id={'createNew'}
        onClick={() => {
          setNewClient(!newClient);
          if (!newClient) {
            newClientClean();
          } else {
            setNewClientLabel('+ New Client');
            setStateSearchText('');
            setStateSearchText('');
            setSelectedCity(null);
            setSelectedState(null);
            setLocation('');
            setAddressLine1('');
            setEmail('');
            setSecondaryPhoneNumber('');
            setPhoneNumber('');
            setFirstname('');
            setLastname('');
            setPostalCode('');
            setSearchText('');
            setClient(null);
          }
        }}
        label={newClientLabel}
      />
      {newClient && (
        <div>
          <div className={classes.twoDivided}>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>First Name</InputLabel>
              <TextField
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={firstname}
                onChange={e => {
                  setFirstname(e.target.value as string);
                  if (client !== null) {
                    client.firstname = e.target.value as string;
                  }
                }}
                variant="outlined"
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Last Name *</InputLabel>
              <TextField
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={lastname}
                onChange={e => setLastname(e.target.value as string)}
                variant="outlined"
              />
            </FormControl>
          </div>
          <div className={classes.twoDivided}>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Phone Number</InputLabel>
              <TextField
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                value={phoneNumber}
                onChange={e =>
                  formatPhoneNumber(e.target.value as string, setPhoneNumber)
                }
                variant="outlined"
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Secondary Phone</InputLabel>
              <TextField
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                value={secondaryPhoneNumber}
                onChange={e =>
                  formatPhoneNumber(
                    e.target.value as string,
                    setSecondaryPhoneNumber,
                  )
                }
                variant="outlined"
              />
            </FormControl>
          </div>
          <FormControl variant="outlined" className={classes.singleField}>
            <InputLabel className={classes.label}>Email</InputLabel>
            <TextField
              type="email"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={email}
              onChange={e => setEmail(e.target.value as string)}
              variant="outlined"
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.singleField}>
            <InputLabel className={classes.label}>Address</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={addressLine1}
              onChange={e => setAddressLine1(e.target.value as string)}
              variant="outlined"
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.singleField}>
            <InputLabel className={classes.label}>Location</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={location}
              onChange={e => setLocation(e.target.value as string)}
              variant="outlined"
            />
          </FormControl>
          <div className={classes.twoDivided}>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>State</InputLabel>
              <Autocomplete
                id="client-select-state-modal"
                options={states}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={evt =>
                      setStateSearchText(evt.target.value as string)
                    }
                  />
                )}
                getOptionLabel={option => option.name.toString()}
                onChange={(evt, value) => setSelectedState(value)}
                value={selectedState}
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>City</InputLabel>
              <Autocomplete
                disabled={selectedState === null}
                id="client-select-city-modal"
                options={cities}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={evt =>
                      setCitySearchText(evt.target.value as string)
                    }
                  />
                )}
                getOptionLabel={option => option.name.toString()}
                onChange={(evt, value) => setSelectedCity(value)}
                value={selectedCity}
              />
            </FormControl>
          </div>
          <FormControl variant="outlined">
            <InputLabel className={classes.label}>Postal Code</InputLabel>
            <TextField
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={postalCode}
              onChange={e => setPostalCode(e.target.value as string)}
              variant="outlined"
            />
          </FormControl>
        </div>
      )}
    </div>
  );
};
