import React, { FC, useState } from 'react';
import {
  List,
  TextField,
  ListProps,
  Datagrid,
  EditButton,
  ShowButton,
  BooleanField,
  NumberField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
import { SearchBox } from '../calendar/SearchBox';
import { Typography } from '@material-ui/core';
import { useStyles } from '../shared/Styles';

export const MemorialOptionList: FC<ListProps> = props => {
  const [textSearch, setTextSearch] = useState('');
  const classes = useStyles();

  return (
    <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
      <div className={classes.title}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Memorial Options
        </Typography>
        <SearchBox
          callback={text => {
            setTextSearch(text);
          }}
        />
      </div>
      <List
        {...props}
        hasEdit={true}
        hasShow={true}
        bulkActionButtons={false}
        filter={{ searchTerm: textSearch, 'order[createdAt]': 'desc' }}
      >
        <Datagrid>
          <TextField source="code" />
          <TextField source="description" />
          <NumberField
            source="price"
            options={{ style: 'currency', currency: 'USD' }}
          />
          <BooleanField source="deleted" />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </List>
    </RolePageProvider>
  );
};
