import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Breed, Client, Consumer, Pet, Species } from './types';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { get } from '../../utils/Api';
import { Button } from 'react-admin';

const styles = makeStyles({
  twoDivided: {
    display: 'flex',
  },
  threeDivided: {
    display: 'flex',
  },
  dividedField: {
    width: '158px',
    marginRight: '16px',
  },
  singleField: {
    width: '100%',
  },
  label: {
    marginBottom: '10.19px',
    marginTop: '22px',
  },
});
interface TopRightActionsProps {
  selectedPet: Pet | null;
  onChangeCallback: Consumer<Pet | null>;
  selectedClient?: Client | string;
  hidePlus?: boolean;
  disabled?: boolean;
}

export const PetSelectBundle: React.FC<TopRightActionsProps> = ({
  selectedPet,
  onChangeCallback,
  selectedClient,
  hidePlus,
  disabled,
}) => {
  const [options, setOptions] = useState<Pet[]>([]);
  const [species, setSpecies] = useState<Species[]>([]);
  const [selectedSpecies, setSelectedSpecies] = useState<Species | null>(
    selectedPet ? selectedPet.species ?? null : null,
  );
  const [breeds, setBreeds] = useState<Breed[]>([]);
  const [selectedBreed, setSelectedBreed] = useState<Breed | null>(
    selectedPet ? selectedPet.breed ?? null : null,
  );
  const [searchText, setSearchText] = useState<string>('');
  const [speciesSearchText, setSpeciesSearchText] = useState<string>('');
  const [breedSearchText, setBreedSearchText] = useState<string>('');
  const [newPet, setNewPet] = useState<boolean>(false);
  const [newPetLabel, setNewPetLabel] = useState<string>('+ New Pet');
  const [pet, setPet] = useState<Pet | null>(null);
  const [name, setName] = useState<string>(selectedPet ? selectedPet.name : '');
  const [lastname, setLastname] = useState<string>(
    selectedPet ? selectedPet.lastname : '',
  );
  const [age, setAge] = useState<number>(selectedPet ? selectedPet.age : 0);
  const [weight, setWeight] = useState<number>(
    selectedPet ? selectedPet.weight : 0,
  );
  const [gender, setGender] = useState<string>(
    selectedPet ? selectedPet.gender : '',
  );
  const classes = styles();
  useEffect(() => {
    setPet(selectedPet);
  }, [selectedPet]);
  useEffect(() => {
    if (pet !== null) {
      onChangeCallback(pet);
    }
  }, [pet, onChangeCallback]);
  useEffect(() => {
    const getFilteredPets = () => {
      get({
        endpoint: `pets?deleted=false${
          searchText !== '' ? '&searchTerm=' + searchText : ''
        }${
          typeof selectedClient === 'string' || selectedClient === undefined
            ? ''
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '&owners.id=' + selectedClient?.id
        }`,
      }).then(async response => {
        const petsJson = await response.json();
        setOptions(petsJson['hydra:member']);
      });
    };

    getFilteredPets();
  }, [searchText, selectedClient]);

  useEffect(() => {
    const getSpecies = () => {
      get({
        endpoint: `species?deleted=false&name=${speciesSearchText}`,
      }).then(async response => {
        const speciesJson = await response.json();
        setSpecies(speciesJson['hydra:member']);
      });
    };

    getSpecies();
  }, [speciesSearchText]);

  useEffect(() => {
    const getBreeds = () => {
      get({
        endpoint: `breeds?deleted=false&species.id=${selectedSpecies?.id}&name=${breedSearchText}`,
      }).then(async response => {
        const breedsJson = await response.json();
        setBreeds(breedsJson['hydra:member']);
      });
    };
    if (selectedSpecies !== null) {
      getBreeds();
    }
  }, [breedSearchText, selectedSpecies]);

  useEffect(() => {
    if (hidePlus) {
      newPetClean();
    }
  }, [hidePlus]);
  useEffect(() => {
    if (pet !== null) {
      onChangeCallback(pet);
    }
  }, [pet, onChangeCallback]);
  useEffect(() => {
    if (selectedPet) {
      const localPet: Pet = {
        id: selectedPet ? selectedPet.id : '',
        name: name,
        lastname: lastname,
        gender: gender,
        age: age,
        weight: weight,
        deceased: false,
        breed: selectedBreed ?? undefined,
        species: selectedSpecies ?? undefined,
        diagnosis: undefined,
        deleted: false,
        referral: '',
        household: [],
        owners: [],
        veterinarians: [],
        memorialOptions: [],
        petNotes: selectedPet.petNotes,
      };
      setPet(localPet);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [age, gender, lastname, name, selectedBreed, weight]);
  const newPetClean = () => {
    setNewPetLabel('- New Pet');
    const localPet: Pet = {
      id: '',
      name: '',
      lastname: '',
      gender: '',
      age: 0,
      weight: 0,
      deceased: false,
      breed: undefined,
      diagnosis: undefined,
      deleted: false,
      referral: '',
      household: [],
      owners: [],
      veterinarians: [],
      memorialOptions: [],
    };
    setPet(localPet);
    setNewPet(true);
  };
  return (
    <div style={{ marginBottom: '20px' }}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel className={classes.label}>Re: Pet Name</InputLabel>
        <Autocomplete
          disabled={newPet || selectedClient === undefined || disabled}
          id="pet-select-modal"
          options={options ?? []}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Search Pets"
              onChange={evt => setSearchText(evt.target.value as string)}
            />
          )}
          getOptionLabel={option =>
            (option.name ? option.name.toString() : '') +
            ' ' +
            (option.lastname ? option.lastname.toString() : '')
          }
          onChange={(evt, value) => setPet(value)}
          value={pet}
        />
      </FormControl>
      <Button
        id={'createNew'}
        disabled={
          hidePlus ||
          selectedClient === undefined ||
          selectedClient === '' ||
          disabled
        }
        onClick={() => {
          setNewPet(!newPet);
          if (!newPet) {
            newPetClean();
          } else {
            setNewPetLabel('+ New Pet');
            setSpeciesSearchText('');
            setBreedSearchText('');
            setSelectedBreed(null);
            setSelectedSpecies(null);
            setPet(null);
          }
        }}
        label={newPetLabel}
      />
      {newPet && (
        <div>
          <div className={classes.twoDivided}>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Pet Name</InputLabel>
              <TextField
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={name}
                onChange={e => {
                  setName(e.target.value as string);
                }}
                variant="outlined"
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Last Name</InputLabel>
              <TextField
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={lastname}
                onChange={e => setLastname(e.target.value as string)}
                variant="outlined"
              />
            </FormControl>
          </div>
          <div className={classes.twoDivided}>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Species</InputLabel>
              <Autocomplete
                id="client-select-species-modal"
                options={species}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={evt =>
                      setSpeciesSearchText(evt.target.value as string)
                    }
                  />
                )}
                getOptionLabel={option => option.name.toString()}
                onChange={(evt, value) => setSelectedSpecies(value)}
                value={selectedSpecies}
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Breed</InputLabel>
              <Autocomplete
                disabled={selectedSpecies === null}
                id="client-select-breed-modal"
                options={breeds}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={evt =>
                      setBreedSearchText(evt.target.value as string)
                    }
                  />
                )}
                getOptionLabel={option => option.name.toString()}
                onChange={(evt, value) => setSelectedBreed(value)}
                value={selectedBreed}
              />
            </FormControl>
          </div>
          <div className={classes.twoDivided}>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Age</InputLabel>
              <TextField
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={age}
                onChange={e => {
                  setAge(Number(e.target.value));
                }}
                variant="outlined"
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Weight</InputLabel>
              <TextField
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={weight}
                onChange={e => setWeight(Number(e.target.value))}
                variant="outlined"
              />
            </FormControl>
          </div>
          <div className={classes.twoDivided}>
            <FormControl variant="outlined" className={classes.dividedField}>
              <InputLabel className={classes.label}>Gender</InputLabel>
              <Autocomplete
                id="client-select-breed-modal"
                options={['female', 'male']}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={evt =>
                      setBreedSearchText(evt.target.value as string)
                    }
                  />
                )}
                getOptionLabel={option =>
                  option.charAt(0).toUpperCase() + option.slice(1)
                }
                onChange={(evt, value) => setGender(value as string)}
                value={gender}
              />
            </FormControl>
          </div>
        </div>
      )}
    </div>
  );
};
