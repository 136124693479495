import { Pet } from '../components/shared/types';
import { patch, post } from './Api';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePet = async (pet: Pet): Promise<any> => {
  const petBody = JSON.stringify({
    name: pet.name,
    aftercareQuote: pet.aftercareQuote,
    euthanasiaQuote: pet.euthanasiaQuote,
    lastname: pet?.lastname,
    gender: pet?.gender,
    age: parseInt(pet?.age ? pet?.age.toString() : '0'),
    weight: parseFloat(pet?.weight ? pet?.weight.toString() : '0'),
    deceased: pet.deceased,
    breed: pet?.breed ? `/breeds/${pet?.breed?.id}` : null,
    species: pet?.species ? `/species/${pet?.species?.id}` : null,
    diagnosis: pet?.diagnosis ? `/diagnoses/${pet?.diagnosis?.id}` : null,
    veterinarians: pet.veterinarians
      ? pet.veterinarians.map(_vet => `/veterinarians/${_vet.id}`)
      : [],
    referral: `${pet?.referral}`,
    dateOfDeath: pet?.dateOfDeath
      ? `${pet?.dateOfDeath.toLocaleString()}`
      : null,
  });
  return await patch({
    endpoint: `pets/${pet.id}`,
    body: petBody,
  });
};

export const updateMemorialOptions = async (pet: Pet): Promise<Response[]> => {
  const promises = [];
  for (const _memorialOption of pet.memorialOptions) {
    if (_memorialOption.memorialOption !== undefined) {
      if (_memorialOption.id === '') {
        const memOptBody = JSON.stringify({
          amount: _memorialOption.amount,
          pet: `/pets/${pet.id}`,
          memorialOption: `/memorial_options/${_memorialOption.memorialOption.id}`,
        });
        const pr = await post({
          endpoint: `pet_memorial_options`,
          body: memOptBody,
        });
        promises.push(pr);
      } else {
        const memOptBody = JSON.stringify({
          amount: _memorialOption.amount,
        });
        const pr = await patch({
          endpoint: `pet_memorial_options/${_memorialOption.id}`,
          body: memOptBody,
        });
        promises.push(pr);
      }
    }
  }
  return promises;
};
