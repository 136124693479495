import React, { FC, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import EventClickArg from '@fullcalendar/common';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import interactionPlugin from '@fullcalendar/interaction';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment-timezone';
import {
  CalendarDate,
  EventInfo,
  FullCalendarEvent,
  Event,
  EventList,
} from '../shared/types';
import { AppointmentFormModal } from '../shared/AppointmentFormModal';
import { get, patch } from '../../utils/Api';
import { RoleLinkProvider } from '../shared/RoleLinkProvider';
import { TopRightActions } from './TopRightActions';
import { formatColor, formatTopic } from '../../utils/StringUtils';
import { SearchBox } from './SearchBox';
import ReactDOM from 'react-dom';
import { Notification } from '../shared/Notification';
import { AppointmentTypeSelector } from './AppointmentTypeSelector';
import { appointmentTypesMap } from '../../utils/AppointmentTypes';
import { AppointmentDetailsModal } from '../shared/AppointmentDetailsModal';
import { CreateNewEventButton } from './CreateNewEventButton';
import { ToggleCalendarPeriod } from './ToggleCalendarPeriod';
import Link from '@material-ui/core/Link';

const styles = makeStyles(theme => {
  return {
    calendarContainer: {
      display: 'contents',
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      // '& .MuiOutlinedInput-input': {
      //   padding: '10px 20px',
      // },
      // '& .MuiInputLabel-formControl': {
      //   top: '-5px',
      // },
      // '& label + .MuiInput-formControl': {
      //   marginTop: 'auto',
      // },
      '& #search-field': {
        margin: 'auto 20px auto auto',
        width: '300px',
        '& .MuiSvgIcon-root': {
          width: 17,
          height: 17,
          fontSize: '17px',
        },
      },
      '& .fc-button, .fc-button-primary:disabled': {
        color: '#373F41',
        background: '#FFF',
        fontWeight: 600,
      },
      '& .fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button': {
        padding: '10px 20px',
        borderRadius: '25px',
        textTransform: 'capitalize',
        width: '90px',
      },
      '& .fc-prev-button, .fc-next-button': {
        width: '28px',
        padding: '9px 0',
        [theme.breakpoints.down('sm')]: {
          padding: '6px 0',
        },
      },
      '& .fc-direction-ltr .fc-toolbar > * > :not(:first-child)': {
        marginLeft: '1em',
      },
      '& .fc-direction-ltr .fc-toolbar > * > .fc-next-button:not(:first-child), .fc-direction-ltr .fc-toolbar > * > .fc-today-button:not(:first-child)': {
        marginLeft: 0,
      },
      '& .fc-prev-button, .fc-next-button, .fc-today-button': {
        textTransform: 'capitalize',
        borderRadius: 0,
      },
      '& .fc-today-button': {
        padding: '10px',
        borderRight: 0,
        borderLeft: 0,
        [theme.breakpoints.down('sm')]: {
          padding: '7px 3px',
        },
      },
      '& .fc-header-toolbar': {
        fontFamily: 'Mulish',
        columnGap: '10px',
      },
      '& .fc-header-toolbar > .fc-toolbar-chunk': {
        whiteSpace: 'nowrap',
      },
      '& .fc-toolbar-title': {
        fontFamily: 'Roboto',
        [theme.breakpoints.down('sm')]: {
          fontSize: '22px',
        },
      },
      '& .fc-timegrid-event': {
        padding: '2px 10px',
        minHeight: '23px',
        fontSize: '0.65em',
        fontFamily: 'Mulish',
        fontWeight: 600,
      },
      '& .fc-daygrid-event': {
        fontSize: '0.65em',
        fontFamily: 'Mulish',
        fontWeight: 600,
      },
      '& .fc-media-screen': {
        [theme.breakpoints.down('sm')]: {
          padding: '0 15px',
        },
      },
      '& .fc .fc-timegrid .fc-daygrid-body': {
        zIndex: 1,
        fontFamily: 'Mulish',
      },
      '& .fc-v-event .fc-event-title, & .fc-event-time': {
        fontSize: '10.4px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '& .fc-timegrid-body, & .fc-scrollgrid-sync-inner': {
        fontFamily: 'Mulish',
      },
      '& .fc-timegrid-axis-frame': {
        whiteSpace: 'nowrap',
      },
    },
    form: {
      width: '100%',
      display: 'flex',
      padding: '15px 0',
    },
    formControl: {
      minWidth: 250,
      marginRight: '10px',
    },
    barElement: {
      marginRight: '10px',
    },
    header: {
      display: 'flex',
      fontFamily: 'Roboto',
      alignItems: 'center',
    },
    mobileHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '19px 19px 14px 19px',
      marginBottom: '20px',
      backgroundColor: '#F9F9F9',
      '& .MuiInputBase-root': {
        '& .MuiSelect-root': {
          color: 'transparent',
        },
      },
      '& .MuiOutlinedInput-input': {
        border: 0,
      },
      '& #searchEntries': {
        backgroundColor: '#FFF',
      },
      '& .MuiButton-text': {
        lineHeight: '20px',
      },
    },
    dayCellContentShow: {
      '& .fc-daygrid-event-harness': {
        display: 'block',
      },
    },
    dayCellContentHide: {
      '& .fc-daygrid-event-harness': {
        display: 'none',
      },
    },
  };
});

export const CalendarView: FC = () => {
  const [searchText, setSearchText] = useState('');
  const [appointmentTypeFilter, setAppointmentTypeFilter] = useState<string[]>(
    [],
  );
  const calendarComponentRef = useRef<FullCalendar>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openFormDialog, setOpenFormDialog] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState<CalendarDate | null>(null);
  const [selectedEntry, setSelectedEntry] = useState<Event | undefined>(
    undefined,
  );
  const classes = styles();
  const notify = useNotify();
  const [events, setEvents] = useState<FullCalendarEvent[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchBarExpanded, setSearchBarExpanded] = useState(false);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const clickEntry = (info: EventClickArg.EventClickArg) => {
    get({
      endpoint: `events/${info.event.id}`,
    }).then(async response => {
      const eventJson = await response.json();
      setSelectedEntry(eventJson as Event);
      setSelectedDate(null);
    });
    setOpenDialog(true);
  };

  const eventChange = async (arg: EventClickArg.EventChangeArg) => {
    let endpoint: string;
    switch (arg.event._def.extendedProps.appointmentType) {
      case 'PlaceholderAppointmentEvent':
        endpoint = 'placeholder_appointment_events';
        break;
      case 'AftercareAppointmentEvent':
        endpoint = 'aftercare_appointment_events';
        break;
      case 'CacAppointmentEvent':
        endpoint = 'cac_appointment_events';
        break;
      case 'EuthanasiaAppointmentEvent':
        endpoint = 'euthanasia_appointment_events';
        break;
      case 'BreakEvent':
        endpoint = 'break_events';
        break;
      default:
        endpoint = 'duty_events';
        break;
    }
    if (arg.oldEvent.allDay !== arg.event.allDay) {
      notify('Duty assignments can only changed by day.');
      arg.revert();
    } else {
      const eventBody = JSON.stringify({
        startTime: arg.event.startStr,
        endTime: arg.event.startStr,
        allDay: arg.event.allDay,
      });
      await patch({
        endpoint: `${endpoint}/${arg.event.id}`,
        body: eventBody,
      });
    }
  };

  const getEvents = (
    _info: EventInfo,
    successCallback: (arg0: FullCalendarEvent[]) => void,
  ) => {
    const userRole = localStorage.getItem('role');
    const eventTypesToLoad = [];
    const all_events = get({
      endpoint: `event_lists?role=${userRole}&endTime%5Bafter%5D=${moment(
        _info.startStr,
      )
        .utc()
        .format()}&startTime%5Bbefore%5D=${moment(_info.endStr)
        .utc()
        .format()}&deleted=false&itemsPerPage=1000`,
    });
    eventTypesToLoad.push(all_events);

    // Load Break and Duty events separately for Doctors because role filter in the endpoint above removes them
    if (userRole === 'ROLE_VETERINARIAN') {
      const break_events = get({
        endpoint: `break_events?endTime%5Bafter%5D=${moment(_info.startStr)
          .utc()
          .format()}&startTime%5Bbefore%5D=${moment(_info.endStr)
          .utc()
          .format()}&deleted=false&itemsPerPage=1000`,
      });
      eventTypesToLoad.push(break_events);

      const duty_events = get({
        endpoint: `duty_events?endTime%5Bafter%5D=${moment(_info.startStr)
          .utc()
          .format()}&startTime%5Bbefore%5D=${moment(_info.endStr)
          .utc()
          .format()}&deleted=false&itemsPerPage=1000`,
      });
      eventTypesToLoad.push(duty_events);
    }

    const allTypeEvents: FullCalendarEvent[] = [];
    Promise.all(eventTypesToLoad).then(async function (eventTypes: Response[]) {
      // @todo Fix explicit any type and remove line below
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const eventTypesJson: any[] = [];
      eventTypes.forEach(function (eventType: Response) {
        eventTypesJson.push(eventType.json());
      });

      Promise.all(eventTypesJson).then(eventTypeEvents => {
        eventTypeEvents.forEach(function (eventTypeEvent) {
          eventTypeEvent['hydra:member']
            .filter((_event: EventList) => {
              if (searchText !== '') {
                return (
                  _event.description
                    .toLowerCase()
                    .search(searchText.toLowerCase()) !== -1 ||
                  (_event.owner !== undefined && _event.owner !== null
                    ? _event.owner.firstname
                        .toLowerCase()
                        .search(searchText.toLowerCase()) !== -1
                    : false) ||
                  (_event.owner !== undefined && _event.owner !== null
                    ? _event.owner.lastname
                        .toLowerCase()
                        .search(searchText.toLowerCase()) !== -1
                    : false) ||
                  (_event.pet !== undefined && _event.pet !== null
                    ? _event.pet.name
                        .toLowerCase()
                        .search(searchText.toLowerCase()) !== -1
                    : false) ||
                  (_event.user !== undefined && _event.user !== null
                    ? _event.user.name
                        .toLowerCase()
                        .search(searchText.toLowerCase()) !== -1
                    : false)
                );
              } else if (appointmentTypeFilter.length > 0) {
                return (
                  appointmentTypesMap.filter(_type => {
                    return (
                      (_event['@type'] === _type.id &&
                        appointmentTypeFilter.find(
                          _filt => _filt === _type.name,
                        ) !== undefined) ||
                      _event['@type'] === 'DutyEvent'
                    );
                  }).length > 0
                );
              } else {
                return true;
              }
            })
            .map((_newEvent: Event) => {
              return {
                id: _newEvent.id,
                title: formatTopic(_newEvent),
                start: _newEvent.startTime,
                end: _newEvent.endTime,
                userName: _newEvent.user?.firstname
                  ? _newEvent.user?.firstname
                  : '' + _newEvent.user?.lastname
                  ? _newEvent.user?.lastname
                  : '',
                userId: _newEvent.user?.id,
                allDay: _newEvent.allDay,
                backgroundColor: formatColor(_newEvent),
                appointmentType: _newEvent['@type'],
                textColor: '#000',
              };
            })
            .forEach(function (singleEvent: FullCalendarEvent) {
              allTypeEvents.push(singleEvent);
            });
        });
        if (JSON.stringify(events) !== JSON.stringify(allTypeEvents)) {
          setEvents(allTypeEvents);
        }
      });
    });
    successCallback(events);
  };

  const search = (text: string) => {
    setSearchText(text);
  };
  const filter = (appointmentType: string[]) => {
    setAppointmentTypeFilter(appointmentType);
  };
  const searchFocusIn = () => {
    setSearchBarExpanded(true);
  };
  const searchFocusOut = () => {
    setSearchBarExpanded(false);
  };
  const topRightActions = (
    <TopRightActions
      buttonAction={() => {
        setSelectedEntry(undefined);
        setSelectedDate({
          allDay: false,
          date: new Date(),
          dateStr: new Date().toDateString(),
        });
        setOpenFormDialog(true);
      }}
      goToDateCallback={date => {
        if (
          date !== null &&
          calendarComponentRef !== undefined &&
          calendarComponentRef.current !== undefined
        ) {
          const calendarApi = calendarComponentRef.current.getApi();
          calendarApi.gotoDate(date?.format('YYYY-MM-DD')); // call a method on the Calendar object
        }
      }}
    />
  );

  useEffect(() => {
    if (!matchesMobile) {
      const container = document.getElementsByClassName(
        'fc-searchField-button',
      )[0];

      if (container) {
        const parent = container.parentElement;
        ReactDOM.render(
          <div className={classes.header}>
            <RoleLinkProvider
              allowed={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_STAFF']}
            >
              <SearchBox callback={search} />
              <AppointmentTypeSelector callback={filter} />
            </RoleLinkProvider>
          </div>,
          parent,
        );
      }
    }
  });

  const headerToolbar = matchesMobile
    ? {
        start: 'title',
        center: '',
        end: 'prev today next',
      }
    : {
        start: 'title',
        center: 'searchField toggleView',
        end: 'dayGridMonth timeGridWeek timeGridDay prev today next',
      };

  const [cellContentClass, setCellContentClass] = useState(
    classes.dayCellContentShow,
  );

  interface AllDayContentProps {
    handleClick: () => void;
    content: boolean;
  }

  const [content, setContent] = useState(true);

  const handleClick = () => {
    setContent(content => !content);

    if (!content) {
      setCellContentClass(classes.dayCellContentShow);
    } else {
      setCellContentClass(classes.dayCellContentHide);
    }
  };

  const AllDayContent: FC<AllDayContentProps> = ({ handleClick, content }) => {
    return (
      <Link component="button" onClick={handleClick}>
        on duty {content ? '-' : '+'}
      </Link>
    );
  };
  return (
    <>
      <RoleLinkProvider
        allowed={['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_STAFF']}
      >
        {matchesMobile && (
          <div className={classes.mobileHeader}>
            <SearchBox
              callback={search}
              searchFocusIn={searchFocusIn}
              searchFocusOut={searchFocusOut}
              searchBarExpanded={searchBarExpanded}
            />
            {!searchBarExpanded && (
              <>
                <AppointmentTypeSelector callback={filter} />
                <ToggleCalendarPeriod
                  changeViewCallback={view => {
                    if (
                      view !== null &&
                      calendarComponentRef !== undefined &&
                      calendarComponentRef.current !== undefined
                    ) {
                      const calendarApi = calendarComponentRef.current.getApi();
                      calendarApi.changeView(view); // call a method on the Calendar object
                    }
                  }}
                />
                <CreateNewEventButton
                  buttonAction={() => {
                    setSelectedEntry(undefined);
                    setSelectedDate({
                      allDay: false,
                      date: new Date(),
                      dateStr: new Date().toDateString(),
                    });
                    setOpenFormDialog(true);
                  }}
                />
              </>
            )}
          </div>
        )}
        {topRightActions}
      </RoleLinkProvider>
      <div className={classes.calendarContainer}>
        <FullCalendar
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={calendarComponentRef}
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          timeZone="local"
          dateClick={(arg: CalendarDate) => {
            if (localStorage.getItem('role') !== 'ROLE_VETERINARIAN') {
              setSelectedEntry(undefined);
              setSelectedDate(arg);
              setOpenFormDialog(true);
            }
          }}
          allDayText="on duty"
          eventChange={eventChange}
          eventClick={clickEntry}
          droppable={false}
          editable={false}
          height="100%"
          firstDay={1}
          expandRows={true}
          initialView={'timeGridDay'}
          events={getEvents}
          headerToolbar={headerToolbar}
          allDayContent={() => (
            <AllDayContent handleClick={handleClick} content={content} />
          )}
          dayCellClassNames={cellContentClass}
        />
        {openFormDialog && (
          <AppointmentFormModal
            callback={({ message, success }) => {
              setOpenFormDialog(false);
              setSuccess(success);
              setSnackbarMessage(message);
              setOpenSnackbar(true);
            }}
            closeCallback={() => setOpenFormDialog(false)}
            selectedDate={selectedDate}
            type={selectedEntry === undefined ? 'create' : 'edit'}
            event={selectedEntry}
          />
        )}
        {openDialog && selectedEntry && (
          <AppointmentDetailsModal
            closeCallback={() => setOpenDialog(false)}
            callback={({ message, success }) => {
              setOpenDialog(false);
              setSuccess(success);
              setSnackbarMessage(message);
              setOpenSnackbar(true);
            }}
            editCallback={() => {
              setOpenDialog(false);
              setOpenFormDialog(true);
            }}
            event={selectedEntry}
            showActionLinks={true}
          />
        )}
        <Notification
          close={() => {
            setOpenSnackbar(false);
          }}
          message={snackbarMessage}
          open={openSnackbar}
          success={success}
        />
      </div>
    </>
  );
};
