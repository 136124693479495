import React, { FC, useState } from 'react';
import {
  BooleanField,
  List,
  TextField,
  ListProps,
  Datagrid,
  EditButton,
  ShowButton,
  Button,
  useNotify,
  FunctionField,
} from 'react-admin';
import { RolePageProvider } from '../shared/RolePageProvider';
import extractUUID from '../../utils/ExtractUUID';
import SendIcon from '@material-ui/icons/Send';
import { apiUrl } from '../../App';
import { rolesMap } from '../../utils/Roles';
import { SearchBox } from '../calendar/SearchBox';
import { Typography } from '@material-ui/core';
import { useStyles } from '../shared/Styles';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const InviteUserButton = ({ record }) => {
  const notify = useNotify();

  const sendInvite = async () => {
    const formData = new FormData();
    formData.append('userId', extractUUID(record.id));
    const response = await fetch(`${apiUrl}/invite_users`, {
      method: 'POST',
      body: JSON.stringify({ userId: extractUUID(record.id) }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
    if (response.ok) {
      notify('User invitation sent successfully');
    } else {
      notify('There was an error with the request.');
    }
  };

  return (
    <Button onClick={sendInvite} label="Send Invite">
      <SendIcon />
    </Button>
  );
};

export const UserList: FC<ListProps> = props => {
  const [textSearch, setTextSearch] = useState('');
  const classes = useStyles();

  return (
    <RolePageProvider allowed={['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']}>
      <div className={classes.title}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          Users
        </Typography>
        <SearchBox
          callback={text => {
            setTextSearch(text);
          }}
        />
      </div>
      <List
        {...props}
        hasEdit={true}
        hasShow={true}
        bulkActionButtons={false}
        filter={{ searchTerm: textSearch, 'order[createdAt]': 'desc' }}
      >
        <Datagrid>
          <TextField source="name" />
          <TextField source="email" />
          <TextField source="telephone" label="Phone" />
          <FunctionField
            sortBy="role"
            label="Role"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            render={record => {
              if (undefined === record) {
                return '';
              }
              const foundRole = rolesMap.find(role => role.id === record.role);
              return foundRole ? `${foundRole.name}` : `${record.role}`;
            }}
          />
          <BooleanField source={'deleted'} />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <InviteUserButton />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </List>
    </RolePageProvider>
  );
};
